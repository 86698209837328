<template>
  <v-dialog v-model="visible" max-width="900px">
    <v-card class="editorPadding">
      <Header @close="close" />

      <v-card-title>
        [{{ reqData && pageTitle }}] {{ subTitle }}
      </v-card-title>

      <UserChangePassWordForm ref="refUserChangePassWordForm" :userData="reqData"/>

      <CommonBtn 
        cancelBtnLabel="CLOSE"
        :propsKey="reqData.userId"
        :isShowDeleteBtn="false"
        saveBtnLabel="RESET PASSWORD"
        @save="saveData"
        @cancel="close"
        />
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import Header from "./comp/Header.vue";
import CommonBtn from "./medicalInfo/comp/CommonBtn.vue";
import UserChangePassWordForm from "./form/UserChangePassWordForm.vue";
export default {
  components: {
    Header,
    CommonBtn,
    UserChangePassWordForm
  },
  data() {
    return {
      visible: false,
      pageTitle: '',
      subTitle: 'User Change PassWord',
      reqData: {},
    };
  },
  methods: {
    Open() {
      this.visible = true;
      this.pageTitle = this.meInfo.loginId;
      this.reqData = this.meInfo;
      this.reqData.password = '';
      this.reqData.rePassword = '';
      setTimeout(() => {
        this.$refs.refUserChangePassWordForm.$refs.refPw.focus();
      }, 500);
    },
    close() {
      this.visible = false;
    },
    saveData() {
      // 필수처리
      if (!this.$refs.refUserChangePassWordForm.$refs.form.validate()) return;

      const $h = this.$helper;
      const D = this.reqData;
      let msg = '';
      console.log('', D);

      // if (!this.$helper.requiredCheck(D.password, RF.refPw, `비밀번호를 입력해주세요`)) return;
      // if (!this.$helper.requiredCheck(D.rePassword, RF.refPw2, `비밀번호 확인을 입력해주세요`)) return;

      if (D.password !== D.rePassword) {
        msg = 'Password and Confirm password must be the same'
        $h.showTostMessage("", msg, "info");
        return;
      }

      if (!confirm(`Would you like to change your password?`)) return;
      D.user_id = D.userId;
      D.endpoint = 'user/resetPw';
      this.saveDataQuery(D).then((res) => {
        if (res === undefined) return;
        $h.showTostMessage("", 'Ok', "success");
        this.visible = false;
        this.signOut().then(() => {
          this.$router.push('/login');
        });
      });
    },
    ...mapActions(['saveDataQuery', 'signOut']),
  },
  computed: {
    ...mapGetters(["meInfo"]),
  },
};
</script>

<style lang="scss">
.modal-content {
  min-width: auto !important;
}
</style>