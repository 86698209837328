<template>
  <vue-perfect-scrollbar
    :settings="{ suppressScrollX: true, wheelPropagation: false }"
    class="h-100 rtl-ps-none ps scroll"
    style="height: 100%"
  >
    <div class="pa-5 relative">
      <v-app-bar
        :color="$vuetify.theme.dark ? 'dark' : 'grey lighten-5'"
        :dark="$vuetify.theme.dark"
        class=""
        fixed
        flat
      >
        <div class="d-flex justify-space-between align-center w-full">
          <h6 class="ma-0">My Account</h6>
          <slot name="userDrawerCloseButton" />
        </div>
      </v-app-bar>
    </div>

    <div class="pa-5 mt-10">
      <div>
        <p class="text-body-2 text--disabled mb-2">
          Time of Login : {{ $moment(PropsAccessInfo.recentLoginDate).format('dddd MM/DD/YYYY, LT') }}
          <br />
          IP : {{ PropsAccessInfo.ip }}
        </p>
      </div>

      <div class="heading-label pa-0 mb-4">User Profile</div>
      
      <div class="d-flex align-center mb-10">
        
        <v-avatar size="60" class="mr-2">
          <img
            v-if="$store.getters.loggedInUser && $store.getters.loggedInUser.imgPath !== ''"
            :src="$store.getters.loggedInUser && $store.getters.loggedInUser.imgPath"
          />
          <v-img v-else src="@/assets/images/faces/doctor_m.png" />
        </v-avatar>
        <div>
          <a class="link-alt" @click="showUserInfoEditor">
            <p class="font-weight-medium ma-0">
              {{ PropsAccessInfo.loginUserName }}(ID:{{ PropsAccessInfo.loginUserId }})
            </p>
          </a>
          <p class="text-body-2 text--disabled mb-2">
            <b> {{ PropsAccessInfo.userType === 'super' ? '(Manager)': ` at ${PropsAccessInfo.hospital_name}` }}</b>
            <br />
          </p>
          <a class="link-alt" @click="showLoginLogs">
            Login Logs
          </a>
        </div>
      </div>
    </div>

    <!-- logs view -->
    <DialogUserLogs ref="refUserLogs" />

    <!-- userInfo view -->
    <DialogUserInfoEdit ref="refUserInfo" />
  </vue-perfect-scrollbar>
</template>

<script>
import DialogUserLogs from '@/components/commonV2/Dialog/DialogUserLogs.vue';
import DialogUserInfoEdit from '@/components/commonV2/Dialog/DialogUserInfoEdit.vue';
export default {
  components: {
    DialogUserLogs,
    DialogUserInfoEdit
  },
  name: "UserDrawer",
  props: {
    PropsAccessInfo: {
      type: [Object]
    }
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    showLoginLogs() {
      this.$refs.refUserLogs.showModal();
    },
    showUserInfoEditor() {
      this.$refs.refUserInfo.showModal();
    },
    // gotoMyInfo() {
    //   let upk = this.$store.getters.loggedInUser.upk;
    //   upk = this.$Base64.encode(upk);
    //   // console.log("", upk, this.$route.query.spk);
    //   if (upk === this.$route.query.spk) {
    //     this.$emit("closeDrawer");
    //     return;
    //   }
    //   this.$router.push(`clinicStaffDetail?spk=${upk}`);
    // },
  },
};
</script>

<style lang="scss">
header.grey.lighten-5.theme--light.v-app-bar.v-app-bar--fixed.v-sheet.v-toolbar.v-toolbar--flat {
  top: 0;
}
</style>
