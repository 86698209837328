<template>
  <v-dialog v-model="visible" max-width="900px">
    <v-card class="editorPadding">
      <Header @close="close" />

      <v-card-title>
        [{{ userData && pageTitle }}] {{ subTitle }}
      </v-card-title>

      <UserInfoForm ref="refUserInfoForm" :userData="userData"/>

      <CommonBtn 
        cancelBtnLabel="CLOSE"
        :propsKey="userData.userId"
        :isShowDeleteBtn="false"
        :isShowChangePassWordBtn="true"
        @changePassWord="changePassWord"
        @save="saveData"
        @cancel="close"
        />
    </v-card>

    <!-- change pass word -->
    <DialogUserResetPassWord ref="refDialogUserResetPassWord" />
  </v-dialog>
</template>
  
<script>
import { mapActions, mapGetters } from 'vuex';
import Header from "./comp/Header.vue";
import CommonBtn from "./medicalInfo/comp/CommonBtn.vue";
import UserInfoForm from "./form/UserInfoForm.vue";
import DialogUserResetPassWord from "./DialogUserResetPassWord.vue";
const formatService = require('@/utils/format.js');

export default {
  components: {
    Header,
    CommonBtn,
    UserInfoForm,
    DialogUserResetPassWord
  },
  data() {
    return {
      visible: false,
      pageTitle: '',
      subTitle: 'Login User Info',
      reqData: {},
      userData: {},
    };
  },
  methods: {
    showModal() {
      this.visible = true;
      this.pageTitle = this.meInfo.loginId;
      this.reqData = this.meInfo;
      this.getData();
    },
    close(e) {
      this.visible = false;
    },
    changePassWord() {
      this.visible = false;
      this.$refs.refDialogUserResetPassWord.Open();
    },
    // 로그 데이터 검색
    async getData() {
      this.userData = {};
      this.reqData.user_id = this.reqData.userId;
      await this.getMeInfo(this.reqData).then(() => {
        this.userData = this.meInfo;
        console.log('this.userData', this.userData);
        
      });
    },
    async saveData() {
      if (!this.$refs.refUserInfoForm.$refs.form.validate()) return;

      const saveLbl = this.userData.userId > 0 ? "Update" : "Save";
      if (!confirm(`Would you like to ${saveLbl}?`)) return;

      const $h = this.$helper;
      this.userData.user_id = this.userData.userId;
      this.userData.endpoint = "staff";
      await this.saveDataQuery(this.userData).then((res) => {
        if (res === undefined) return;
        $h.showTostMessage("",$h.commonMessage("S"),"success");
        this.visible = false;
        this.$emit("complete", res);
        this.signOut().then(() => {
          this.$router.push('/login');
        });
      });
    },
    ...mapActions(['getMeInfo', , "saveDataQuery", 'signOut']),
  },
  computed: {
    ...mapGetters(["meInfo"]),
  },
};
</script>