<template>
  <v-dialog v-model="visible" max-width="900px">
    <v-card class="editorPadding">
      <Header @close="close" />

      <v-card-title>
        [{{ userData && pageTitle }}] {{ subTitle }}
      </v-card-title>

      <div>{{ $helper.commaNum(count) }}Ea</div> 

      <DataTable
        ref="DataTable"
        :loading="loading"
        :loadingText="loadingText"
        :perPage="5"
        :tableKey="'rowSeq'"
        :footerHide="false"
        :singleSelect="true"
        :showSelect="false"
        :columns="userLogTableColumns"
        :inputData="List"
      />

      <CommonBtn 
        :isShowSaveBtn="false"
        cancelBtnLabel="CLOSE"
        @cancel="close"
        />
    </v-card>
  </v-dialog>
</template>
  
<script>
import { mapActions, mapGetters } from 'vuex';
import Header from "./comp/Header.vue";
import CommonBtn from "./medicalInfo/comp/CommonBtn.vue";
import DataTable from "@/components/commonV2/table/DataTable.vue";
const formatService = require('@/utils/format.js');

export default {
  components: {
    Header,
    CommonBtn,
    DataTable
  },
  data() {
    return {
      count: 0,
      List: [],
      visible: false,
      loading: false,
      loadingText: "Loading list...",
      pageTitle: '',
      subTitle: 'Login Logs',
      userData: {},
      userLogTableColumns: formatService.userLogTableColumns(),
    };
  },
  methods: {
    showModal() {
      console.log('showModal');
      
      this.visible = true;
      this.pageTitle = this.meInfo.loginId;
      this.userData = this.meInfo;
      this.getData();
    },
    close(e) {
      this.visible = false;
    },
    // 로그 데이터 검색
    async getData() {
      this.userData.user_id = this.userData.userId;
      this.userData.endpoint = 'user/loginLogs';
      await this.getDataQuery(this.userData).then((resP) => {
        this.count = resP.count;
        let res = resP.data;
        if (0 < res.length) {
          res = res.map((item, index) => ({
            ...item,
            no: res.length - index,
            regDateLabel: this.$helper.changeDateToStringForDp(item.regDate, true)
          }));
        }
        this.List = res;
      });
    },
    ...mapActions(['getDataQuery']),
  },
  computed: {
    ...mapGetters(["meInfo"]),
  },
};
</script>