<template>
  <v-card-text>
    <v-form ref="form">
      <v-row>
        <v-text-field
          label="Password"
          type="password"
          ref="refPw"
          v-model="userData.password"
          :rules="[passwordRules.required, passwordRules.counter, passwordRules.check]"
          required
        />
      </v-row>
      <v-row>
        <v-text-field
          label="Confirm Password"
          type="password"
          ref="refPw2"
          v-model="userData.rePassword"
          :rules="[passwordRules.required, passwordRules.counter, passwordRules.check]"
          required
        />
      </v-row>
    </v-form>
  </v-card-text>
</template>
<script>
import InputName3Type from "@/components/commonV2/ui/InputName3Type.vue";
import RadioComps from "@/components/commonV2/ui/RadioComps.vue";
const formatService = require("@/utils/format.js");
const rulesService = require("@/utils/rules.js");

export default {
  components: {
    InputName3Type,
    RadioComps,
  },
  props: {
    userData: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      passwordRules: rulesService.passwordRules(),
    };
  },
  methods: {
  },
};
</script>
<style lang="">
</style>