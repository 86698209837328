<template>
  <v-card-text>
    <v-form ref="form">
      <v-row>
        <v-text-field
          label="Login Id"
          ref="refLoginId"
          v-model="userData.loginId"
          :rules="[idRules.required]"
          required
          :disabled="true"
        />
      </v-row>
      <v-row>
        <v-text-field
          label="E-mail"
          ref="refEmail"
          v-model="userData.email"
          :rules="[emailRules.required, emailRules.email]"
          required
        />
      </v-row>
      <v-row>
        <InputName3Type :data="userData" />
      </v-row>
      <v-row>
        <v-text-field
          label="Mobile"
          ref="refCell"
          v-model="userData.cell"
          :rules="[cellRules.required, cellRules.min, cellRules.max]"
        />
      </v-row>
      <v-row>
        <RadioComps
          :items="genderData"
          :prosSelected="genderValue"
          @changeType="onChangeGender"
        />
      </v-row>
    </v-form>
  </v-card-text>
</template>
<script>
import InputName3Type from "@/components/commonV2/ui/InputName3Type.vue";
import RadioComps from "@/components/commonV2/ui/RadioComps.vue";
const formatService = require("@/utils/format.js");
const rulesService = require("@/utils/rules.js");

export default {
  components: {
    InputName3Type,
    RadioComps,
  },
  props: {
    userData: {
      type: [Object, Array],
    },
  },
  watch: {
    userData(val) {
      this.genderValue = val.gender;
    },
  },
  data() {
    return {
      genderValue: this.userData.gender, // 초기값
      genderData: formatService.genderTypeItems(),
      idRules: rulesService.idRules(),
      filedRules: rulesService.filedRules(),
      emailRules: rulesService.emailRules(),
      eligibleRules: rulesService.eligibleRules(),
      cellRules: rulesService.cellRules(),
    };
  },
  methods: {
    onChangeGender(e) {
      this.userData.gender = e;
    },
  },
};
</script>
<style lang="">
</style>